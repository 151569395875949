<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-row v-if="dashboardLoading">
      <template v-for="cardItem in cards">
        <v-col :key="`loading_${cardItem.name}`" cols="12" md="6">
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-two-line"
          />
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <template v-for="cardItem in cards">
        <v-col :key="`stats_${cardItem.name}`" cols="12" md="6">
          <v-card class="d-flex">
            <div class="d-flex justify-center flex-column">
              <v-card-title class="text-h5">
                <span v-if="getTotal(cardItem.name) && getTotal(cardItem.name) > 0" style="word-break: normal;">
                  {{ getTotal(cardItem.name) }}
                  {{ cardItem.title|pluralize(getTotal(cardItem.name)) }}
                  {{ cardItem.titleSuffix }}
                </span>
                <span v-else style="word-break: normal;">{{ cardItem.empty }}</span>
              </v-card-title>
              <v-card-subtitle v-if="$store.state.establishments.activeEstablishmentId">
                pour l'établissement {{ getEstablishmentName }}
              </v-card-subtitle>
            </div>

            <v-spacer />
            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="cardItem.avatar" contain height="100%" />
            </v-avatar>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  const  apiResourcesTTL = 60 * 10 * 1000
  export default {
    name: 'Dashboard',
    components: {},
    data: function() {
      return {
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2
        },
        cards: [
          {
            name: 'servicesCount',
            title: 'service',
            titleSuffix: '',
            empty: 'Aucun service',
            avatar: require('@/assets/img/illustrations/services.svg')
          },
          {
            name: 'professionalsCount',
            title: 'professionnel',
            titleSuffix: '',
            empty: 'Aucun professionnel',
            avatar: require('@/assets/img/illustrations/doctors.svg')
          },
          {
            name: 'lastMonthStaysCount',
            title: 'admission',
            titleSuffix: ' le mois dernier',
            empty: 'Aucune admission le mois dernier',
            avatar: require('@/assets/img/illustrations/specialities.svg')
          }
        ]
      }
    },
    computed: {
      ...mapState('establishments', ['allEstablishments', 'activeEstablishmentId']),
      ...mapGetters(['dashboardStats', 'dashboardLoading', 'dashboardError']),
      getEstablishmentName() {
        if(this.$store.state.establishments.activeEstablishmentId) {
          const selectedEstablishmentData = this.$store.state.establishments.allEstablishments.find(
            establishment => establishment.id === this.$store.state.establishments.activeEstablishmentId
          )
          return selectedEstablishmentData ? selectedEstablishmentData.label : null
        }
        return null
      }
    },
    watch: {
      '$store.state.establishments.activeEstablishmentId': {
        handler() {
          this.getStats()
        },
        immediate: true
      },
      '$store.state.dashboard.error': {
        handler() {
          this.handleError()
        },
        immediate: true
      }
    },
    methods: {
      ...mapActions(['fetchStats']),
      handleError () {
        if(!this.$store.state.dashboard.error) {
          return
        }
        this.$root.$emit('error', this.$store.state.dashboard.error)
      },
      async getStats() {
        const establishment = this.$store.state.establishments.activeEstablishmentId || 'all'
        const now = new Date().getTime()
        // if not existing or if timestamp is older than conf TTL
        if(!this.dashboardStats[establishment] || (this.dashboardStats[establishment].timestamp
          && now > this.dashboardStats[establishment].timestamp + apiResourcesTTL)){
          await this.fetchStats(this.$store.state.establishments.activeEstablishmentId)
        }
      },
      getTotal(element) {
        const selectedEstablishment = this.$store.state.establishments.activeEstablishmentId || 'all'
        const contextStats = this.dashboardStats[`${selectedEstablishment}`]
        if(contextStats){
          return contextStats[`${element}`]
        }
        return null
      }
    }
  }
</script>
